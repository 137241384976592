
            @import 'src/styles/mixins.scss';
        
.menu-item {
    position: relative;

    @include md {
        &::before {
            content: "";
            position: absolute;
            height: 90%;
            width: 1px;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: lightgrey;
        }
        &:last-child {
            &::before {
                content: none;
            }
        }
    }
}

.footer {
    @include handleAllBreakpoints(
        "background-color",
        "backgroundColor",
        "var(--global-headerAndFooter-footer-background-color, #fff)"
    );
    @include handleAllBreakpoints("color", "textColor", "var(--global-headerAndFooter-footer-text-color, #000)");

    border-color: #e5e5e5;
    position: relative;
    .inner {
        @include handleAllBreakpoints("border-top", "borderTop", "1px solid #e5e5e5");
        @include handleAllBreakpoints("box-shadow", "boxShadow", "none");
        border-color: #e5e5e5;
    }
}
.footer-menu {
    @media screen and (max-width: 414px) {
        margin-left: -20px;
        margin-right: -20px;
    }
}
