@use "sass:string";

$xs: 340px;
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;
$xxxl: 2024px;

@mixin xs($containerName: null) {
    @if $containerName {
        @container #{$containerName} (min-width: #{$xs}) {
            @content;
        }
    } @else {
        @media (min-width: #{$xs}) {
            @content;
        }
    }
}

@mixin sm($containerName: null) {
    @if $containerName {
        @container #{$containerName} (min-width: #{$sm}) {
            @content;
        }
    } @else {
        @media (min-width: #{$sm}) {
            @content;
        }
    }
}

@mixin md($containerName: null) {
    @if $containerName {
        @container #{$containerName} (min-width: #{$md}) {
            @content;
        }
    } @else {
        @media (min-width: #{$md}) {
            @content;
        }
    }
}

@mixin lg($containerName: null) {
    @if $containerName {
        @container #{$containerName} (min-width: #{$lg}) {
            @content;
        }
    } @else {
        @media (min-width: #{$lg}) {
            @content;
        }
    }
}

@mixin xl($containerName: null) {
    @if $containerName {
        @container #{$containerName} (min-width: #{$xl}) {
            @content;
        }
    } @else {
        @media (min-width: #{$xl}) {
            @content;
        }
    }
}

@mixin xxl($containerName: null) {
    @if $containerName {
        @container #{$containerName} (min-width: #{$xxl}) {
            @content;
        }
    } @else {
        @media (min-width: #{$xxl}) {
            @content;
        }
    }
}

@mixin xxxl($containerName: null) {
    @if $containerName {
        @container #{$containerName} (min-width: #{$xxxl}) {
            @content;
        }
    } @else {
        @media (min-width: #{$xxxl}) {
            @content;
        }
    }
}

@mixin handleAllBreakpoints($property, $prefix, $fallback: "", $important: "") {
    $split-fallbacks: string.split($fallback, "::");
    $fallbacks-length: length($split-fallbacks);

    $split-prefix: string.split($prefix, "::");
    $split-prefix-length: length($split-prefix);

    $fallback-sm: "";
    $fallback-md: "";
    $fallback-lg: "";
    $fallback-xl: "";
    $fallback-xxl: "";
    $original-prefix: "";
    $fallback-prefix: "";

    @if ($split-prefix-length == 1) {
        $original-prefix: nth($split-prefix, 1);
    }
    @if ($split-prefix-length == 2) {
        $original-prefix: nth($split-prefix, 1);
        $fallback-prefix: nth($split-prefix, 2);
    }

    @if ($fallbacks-length == 1) {
        $fallback-sm: nth($split-fallbacks, 1);
        $fallback-md: nth($split-fallbacks, 1);
        $fallback-lg: nth($split-fallbacks, 1);
        $fallback-xl: nth($split-fallbacks, 1);
        $fallback-xxl: nth($split-fallbacks, 1);
    } @else if ($fallbacks-length == 2) {
        $fallback-sm: nth($split-fallbacks, 1);
        $fallback-md: nth($split-fallbacks, 2);
        $fallback-lg: nth($split-fallbacks, 2);
        $fallback-xl: nth($split-fallbacks, 2);
        $fallback-xxl: nth($split-fallbacks, 2);
    } @else if ($fallbacks-length == 3) {
        $fallback-sm: nth($split-fallbacks, 1);
        $fallback-md: nth($split-fallbacks, 2);
        $fallback-lg: nth($split-fallbacks, 3);
        $fallback-xl: nth($split-fallbacks, 3);
        $fallback-xxl: nth($split-fallbacks, 3);
    } @else if ($fallbacks-length == 4) {
        $fallback-sm: nth($split-fallbacks, 1);
        $fallback-md: nth($split-fallbacks, 2);
        $fallback-lg: nth($split-fallbacks, 3);
        $fallback-xl: nth($split-fallbacks, 4);
        $fallback-xxl: nth($split-fallbacks, 4);
    } @else if ($fallbacks-length == 5) {
        $fallback-sm: nth($split-fallbacks, 1);
        $fallback-md: nth($split-fallbacks, 2);
        $fallback-lg: nth($split-fallbacks, 3);
        $fallback-xl: nth($split-fallbacks, 4);
        $fallback-xxl: nth($split-fallbacks, 5);
    }

    #{$property}: var(
            --#{$original-prefix}-sm,
            var(--#{$fallback-prefix}-sm, string.unquote($fallback-sm))
        )
        #{$important};
    @include md {
        #{$property}: var(
                --#{$original-prefix}-md,
                var(--#{$fallback-prefix}-md, string.unquote($fallback-md))
            )
            #{$important};
    }
    @include lg {
        #{$property}: var(
                --#{$original-prefix}-lg,
                var(--#{$fallback-prefix}-lg, string.unquote($fallback-lg))
            )
            #{$important};
    }
    @include xl {
        #{$property}: var(
                --#{$original-prefix}-xl,
                var(--#{$fallback-prefix}-xl, string.unquote($fallback-xl))
            )
            #{$important};
    }
    @include xxl {
        #{$property}: var(
                --#{$original-prefix}-xxl,
                var(--#{$fallback-prefix}-xxl, string.unquote($fallback-xxl))
            )
            #{$important};
    }
}

@mixin handleTextBlockVariables() {
    h1 {
        @include handleAllBreakpoints("color", "contentHeading1Color", "black");
        @include handleAllBreakpoints(
            "font-size",
            "contentHeading1Size",
            "2.25em"
        );
        @include handleAllBreakpoints(
            "font-weight",
            "contentHeading1Weight",
            "800"
        );
        @include handleAllBreakpoints(
            "margin",
            "contentHeading1Margin",
            "0 0 1.5em 0"
        );
        @include handleAllBreakpoints(
            "text-align",
            "contentHeading1Align",
            "left"
        );
    }

    h2 {
        @include handleAllBreakpoints("color", "contentHeading2Color", "black");
        @include handleAllBreakpoints(
            "font-size",
            "contentHeading2Size",
            "1.5em"
        );
        @include handleAllBreakpoints(
            "font-weight",
            "contentHeading2Weight",
            "700"
        );
        @include handleAllBreakpoints(
            "margin",
            "contentHeading2Margin",
            "0 0 1.25em 0"
        );
        @include handleAllBreakpoints(
            "text-align",
            "contentHeading2Align",
            "left"
        );
    }

    h3 {
        @include handleAllBreakpoints("color", "contentHeading3Color", "black");
        @include handleAllBreakpoints(
            "font-size",
            "contentHeading3Size",
            "1.25em"
        );
        @include handleAllBreakpoints(
            "font-weight",
            "contentHeading3Weight",
            "600"
        );
        @include handleAllBreakpoints(
            "margin",
            "contentHeading3Margin",
            "0 0 1em 0"
        );
        @include handleAllBreakpoints(
            "text-align",
            "contentHeading3Align",
            "left"
        );
    }

    p {
        @include handleAllBreakpoints(
            "color",
            "contentParagraphColor",
            "black"
        );
        @include handleAllBreakpoints(
            "font-size",
            "contentParagraphSize",
            "1.25em"
        );
        @include handleAllBreakpoints(
            "font-weight",
            "contentParagraphWeight",
            "400"
        );
        @include handleAllBreakpoints(
            "margin",
            "contentParagraphMargin",
            "0 0 .75em 0"
        );
        @include handleAllBreakpoints(
            "text-align",
            "contentParagraphAlign",
            "left"
        );
        @include handleAllBreakpoints(
            "line-height",
            "contentParagraphLineHeight",
            "normal"
        );
    }
}

@mixin custom-radio($color) {
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: "";
        min-width: 18px;
        min-height: 18px;
        border-radius: 100%;
        background: linear-gradient(180deg, #e8e8e8 0%, #f7f7f7 100%);
        border: 1px solid $color;
        box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.1);
        margin-right: 15px;
    }
    [type="radio"]:checked + label:before {
        content: "";
        width: 16px;
        height: 16px;
        background: $color;
        border: 3px solid #efefef;
        outline: 1px solid $color;
        margin-left: 1px;
    }
}

@mixin glow-effect {
    &:not([disabled]) {
        position: relative;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        &::before {
            content: "";
            background: rgba(white, 0.4);
            width: 60%;
            height: 100%;
            top: 0%;
            left: -125%;
            transform: skew(30deg);
            position: absolute;
            transition: left 0.4s ease-in-out;
        }
        &:hover {
            &::before {
                left: 125%;
            }
        }
        [dir="rtl"] & {
            &::before {
                left: 125%;
            }
            &:hover {
                &::before {
                    left: -125%;
                }
            }
        }
    }
}

@mixin button {
    @apply flex justify-center gap-2 items-center px-8 py-2 relative;
    a {
        &:before {
            content: "";
            position: absolute;
            inset: 0;
        }
    }

    overflow: hidden;

    &:after {
        background: #fff;
        content: "";
        height: 155px;
        left: -75px;
        opacity: 0.2;
        position: absolute;
        top: -50px;
        transform: rotate(35deg);
        transition: all 250ms linear;
        width: 50px;
        // z-index: 10;
    }

    &:hover {
        &:after {
            left: 120%;
            transition: all 250ms linear;
        }
    }
}
